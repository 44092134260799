import { observable } from "@nx-js/observer-util";
import { GetModelConfig, Model, ModelConstructor } from "./model";
import { Query } from "./query";
import Debug from "debug";
import { isNil } from "@mixitone/util";

const debug = Debug("oom:collection");

export type CollectionDescriptor<M extends Model<any>> = {
  model: () => ModelConstructor<M>;
  key: string;
  order?: string;
};
export type Collections = {
  [index: string]: CollectionDescriptor<any>;
};

interface SubscriptionOptions {
  insert?: boolean;
  update?: boolean;
}

export type Collection<M extends Model<any>> = Array<M> & {
  query: Query<M, GetModelConfig<M>>;
  /**
   * Load the collection from the database
   */
  load(): Promise<void>;
  /**
   * Reload the collection from the database
   */
  reload(): Promise<void>;
  /**
   * Wait for the collection to load. Assumes other code is going to call load()
   */
  wait(): Promise<void>;
  /**
   * Has the collection been loaded?
   */
  loaded: boolean;
  /**
   * Set the collection to the given data
   */
  set(data: Array<M>): void;
  /**
   * find a record by id
   */
  findById(id: string): M | undefined;
  /**
   * Check if we have an id
   */
  hasId(id: string): boolean;
  /**
   * Remove a record by id
   */
  removeById(id: string): void;
  /**
   * Add a record to the collection if it doesn't already exist
   */
  add(record: M): void;
  /**
   * Save all records in the collection
   */
  save(): Promise<void>;

  subscribe(options: SubscriptionOptions): Promise<() => void>;

  count(): number;
};

export function createCollection<D extends CollectionDescriptor<any>>(
  on: Model<any>,
  key: string,
  ref: D
): Collection<any> {
  const container: Collection<Model<any>> = observable([]) as any;
  container.loaded = false;
  let loading = false;
  const onLoad: Array<(value: any) => void> = [];
  const onLoaded = () => {
    while (onLoad.length > 0) {
      onLoad.shift()!(void 0);
    }
  };
  // This lets us know if we're the latest load request and cancel otherwise
  let loadingCount = 0;

  const cancel = () => {
    loadingCount += 1;
    loading = false;
  };

  Object.defineProperty(container, "query", {
    get: (): Query<any, any> => {
      let query = ref.model().query().eq(ref.key, on.id);
      if (ref.order) {
        query = query.orderBy(ref.order, "asc");
      }
      return query;
    },
  });
  Object.defineProperty(container, "load", {
    value: async function load(this: Collection<any>) {
      if (container.loaded) return;
      if (loading) {
        return new Promise((resolve) => {
          onLoad.push(resolve);
        });
      }

      // Prevent overwriting manually added records
      if (container.length > 0)
        throw new Error(`Records already added to ref ${key}`);

      loading = true;
      // Increment the loading count and store it for a later check
      let myLoadingCount = (loadingCount += 1);

      const data = await this.query.all();

      // If the loading count has changed, we're not the latest load request
      if (myLoadingCount !== loadingCount) return;

      container.push(...(data as any));
      container.loaded = true;
      loading = false;

      // unshift each callback and call it
      onLoaded();
    },
  });
  Object.defineProperty(container, "count", {
    value: function count(this: Collection<any>) {
      if (this.loaded) {
        return this.length;
      } else {
        const data = on.data.get(key);
        if (Array.isArray(data) && !isNil(data[0]?.count)) {
          return data[0].count as number;
        }
      }

      console.log("on.data", key, on);
      throw new Error(`Cannot get count of ${key}, collection not loaded`);
    },
  });
  container.wait = async () => {
    if (container.loaded) return;
    return new Promise((resolve) => {
      onLoad.push(resolve);
    });
  };
  container.reload = async () => {
    cancel();
    container.loaded = false;
    container.splice(0, container.length);
    await container.load();
  };
  container.set = (data: any[]) => {
    cancel();
    const wasLoaded = container.loaded;
    container.loaded = true;
    container.splice(0, container.length);
    container.push(...data);

    if (!wasLoaded) {
      onLoaded();
    }
  };
  container.findById = (id: string) => {
    return container.find((r) => r.id === id);
  };
  container.hasId = (id: string) => {
    return container.some((r) => r.id === id);
  };
  container.removeById = (id: string) => {
    const index = container.findIndex((r) => r.id === id);
    if (index === -1) return;
    container.splice(index, 1);
  };
  container.save = async () => {
    debug("Saving collection", key);
    await Promise.all(container.map((r) => r.save()));
  };
  container.add = (record: Model<any>) => {
    if (!container.hasId(record.uniqueId)) {
      const order = ref.order;

      // Directly push the record if there's no order specified
      if (!order) {
        container.push(record);
        return;
      }

      // Find the position for insertion based on the order key
      const position = container.findIndex(
        (item) => record.readAttribute(order) < item.readAttribute(order)
      );

      // Insert the record at the found position or push to the end if not found
      if (position === -1) {
        container.push(record);
      } else {
        container.splice(position, 0, record);
      }
    }
  };
  container.subscribe = async (options: SubscriptionOptions) => {
    if (!container.loaded && !loading)
      throw new Error("Cannot subscribe to unloaded collection");
    await container.wait();
    return await container.query.subscribe((data) => {
      if (
        options.insert &&
        data.type === "INSERT" &&
        container.query.matches(data.model)
      ) {
        container.add(data.model);
      }

      if (data.type === "DELETE") {
        container.removeById(data.model.uniqueId);
      }

      if (options.update && data.type === "UPDATE") {
        if (container.hasId(data.model.uniqueId)) {
          if (!container.query.matches(data.model)) {
            container.removeById(data.model.uniqueId);
          } else {
            const record = container.findById(data.model.uniqueId)!;
            record.updateAttributes(data.model.rawAttributes, true);
          }
        } else {
          if (container.query.matches(data.model)) {
            container.push(data.model);
          }
        }
      }
    });
  };

  return container;
}
