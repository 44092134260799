import Debug from "debug";
import { Model, ModelAttributes, model, modelConfig } from "@mixitone/oom";
import { ValidationError } from "@mixitone/oom";
import { arraySwap, isNil, isPresent, shuffle } from "@mixitone/util";
import { setAccountId } from "./concerns/SetAccountId";

const debug = Debug("oom:model:Game");

const config = modelConfig(
  {
    account_id: {
      type: "string",
    },
    game_set_id: {
      type: "string",
    },
    index: {
      type: "number",
    },
    is_deleted: {
      type: "boolean",
    },
    player_1_id: {
      type: "string",
    },
    player_2_id: {
      type: "string",
    },
    player_3_id: {
      type: "string",
    },
    player_4_id: {
      type: "string",
    },
    players: {
      type: "json",
      jsonType: {
        locked: false,
        current: [],
        winners: [],
        losers: [],
      } as {
        locked?: boolean;
        current: Array<string | undefined>;
        winners?: Array<string>;
        losers?: Array<string>;
      },
    },
    reconciled: {
      type: "boolean",
    },
    updated_at: {
      type: "datetime",
    },
  },
  {},
  {},
);

interface Game extends ModelAttributes<typeof config> {}

@setAccountId
@model("games", config)
class Game extends Model<typeof config> {
  static override tableName: "games" = "games";
  static override config = config;

  undoStack: Array<(typeof this)["players"]> = [];
  private _enableUndo = true;

  clearUndo() {
    this.undoStack = [];
  }

  startBatchUndo() {
    if (!this._enableUndo) return;
    this._enableUndo = false;
    this.undoStack.push({
      locked: this.players?.locked,
      current: this.players?.current.slice() ?? [],
      winners: this.players?.winners?.slice() ?? [],
      losers: this.players?.losers?.slice() ?? [],
    });
  }

  endBatchUndo() {
    this._enableUndo = true;
  }

  get locked() {
    return this.players?.locked ?? false;
  }

  lock() {
    this.players = {
      ...this.players!,
      locked: true,
    };
  }

  unlock() {
    this.players = {
      ...this.players!,
      locked: false,
    };
  }

  get playerCount() {
    return this.activePlayerIds().length;
  }

  activePlayerIds() {
    return this.playerIds.filter((id) => !isNil(id)) as string[];
  }

  get playerIds() {
    const playerIds = this.players?.current ?? [];
    return [playerIds[0], playerIds[1], playerIds[2], playerIds[3]].map((id) => {
      if (isNil(id)) return undefined;
      return id;
    }) as [string | undefined, string | undefined, string | undefined, string | undefined];
  }

  set playerIds(ids: Array<string | undefined>) {
    this.players = {
      ...this.players!,
      current: [ids[0], ids[1], ids[2], ids[3]],
    };
  }

  get winners() {
    return new Set(this.players?.winners ?? []);
  }

  get losers() {
    return new Set(this.players?.losers ?? []);
  }

  hasPlayer(id: string) {
    return this.playerIds.includes(id);
  }

  addPlayer(id: string) {
    if (this.full()) throw new Error("This game is full, cannot add a player");
    const ids = this.playerIds.slice();
    const index = ids.findIndex((id) => isNil(id));
    ids[index] = id;
    this.playerIds = ids;
  }

  addPlayers(ids: Array<string>) {
    ids.forEach((id) => this.addPlayer(id));
  }

  setPlayerAt(index: number, id: string | undefined) {
    const ids = this.playerIds.slice();
    ids[index] = id;
    this.playerIds = ids;
  }

  removePlayers(ids: Array<string>) {
    ids.forEach((id) => this.removePlayer(id));
  }

  removePlayer(id: string) {
    if (!this.playerIds.includes(id)) return;

    this.playerIds = this.playerIds.map((playerId) => {
      if (playerId === id) return undefined;
      return playerId;
    });
  }

  swapPlayer(oldId: string, newId: string) {
    if (!this.playerIds.includes(oldId)) throw new Error("Cannot swap player that is not in game");
    const index = this.playerIds.findIndex((id) => id === oldId);
    this.setPlayerAt(index, newId);
  }

  full() {
    return this.playerCount === 4;
  }

  valid() {
    return [2, 4].includes(this.playerCount);
  }

  get canUndo() {
    return this.undoStack.length > 0;
  }

  undo() {
    if (!this.canUndo) return;

    const undoState = this.undoStack.pop();
    if (!undoState) return;
    this.players = { ...undoState };
  }

  clear() {
    this.playerIds = [undefined, undefined, undefined, undefined];
  }

  /**
   * @param groupedPlayers key is group id, value is array of player ids
   * @param playerGroups key is player id, value is group id
   * @param all all is true if the only games that are not full already have 2 players
   * @param groupExclusions key is group id, value is array of group ids that should not be matched with
   * @returns
   */
  fill(
    groupedPlayers: Record<string, string[]>,
    playerGroups: Record<string, string>,
    groupExclusions: Record<string, string[]>,
    playerPairs: Record<string, string>,
  ) {
    if (this.full()) return;
    debug("Filling game", this.index);

    const groupPlayerCount = (group: string): { singles: number; pairs: number; length: number } => {
      const players = groupedPlayers[group];
      const countedPlayers = new Set();
      let singles = 0;
      let pairs = 0;

      players.forEach((player) => {
        if (countedPlayers.has(player)) return;
        const partner = playerPairs[player];
        if (partner) {
          countedPlayers.add(partner);
          pairs++;
        } else {
          singles++;
        }
      });

      return {
        length: players.length,
        singles: singles,
        pairs: pairs,
      };
    };

    const groupsWithNPlayers = (n: number) =>
      Object.keys(groupedPlayers).filter((key) => {
        const count = groupPlayerCount(key);
        if (count.singles >= n) return true;
        if (n % 2 === 0) {
          return count.pairs * 2 + count.singles >= n;
        }
        const leftOver = n - count.pairs * 2;
        return count.singles >= leftOver;
      });

    /**
     * Pop n players from the groupedPlayers. All players will be from the same group
     *
     * @param n number of players to pop
     * @param options
     * @param options.group group id to pop from
     * @param options.canPlay array of player ids the popped players can play with in regards to groupExclusions
     */
    const popNPlayers = (n: number, options: { group?: string; canPlay?: string[] } = {}) => {
      const { group, canPlay } = options;
      let suitableGroups = groupsWithNPlayers(n).filter((key) => isNil(group) || key === group);

      if (!isNil(canPlay)) {
        const canPlayGroups = [...new Set(canPlay.map((player) => playerGroups[player]))];
        const excludedGroups = new Set(canPlayGroups.map((group) => groupExclusions[group]).flat());
        suitableGroups = suitableGroups.filter((group) => !excludedGroups.has(group));
      }

      suitableGroups = shuffle(suitableGroups);

      while (true) {
        const suitableGroup = suitableGroups.pop();
        if (!suitableGroup) break;

        let groupPlayers = shuffle(groupedPlayers[suitableGroup]);

        // Select the first n players from the sorted list
        let returnPlayers = groupPlayers.slice(0, n);

        let i = 0;
        while (true) {
          let p = returnPlayers[i];
          if (!p) break;
          let partner = playerPairs[p];
          if (!partner) {
            i++;
            continue;
          }
          const partnerIndex = returnPlayers.indexOf(partner);
          if (partnerIndex === -1) {
            if (i + 1 >= n) {
              // Either replace the player with one who does not have a partner or abort
              const replacement = groupPlayers
                .reverse()
                .find((p) => !returnPlayers.includes(p) && !playerPairs[p]);
              if (!replacement) {
                returnPlayers = [];
                break;
              }
              returnPlayers[i] = replacement;
              break;
            } else {
              // Partner is in a different group, bring them in
              returnPlayers[i + 1] = partner;
              i += 2;
              continue;
            }
          } else {
            // Partner is in the same group, move them up
            arraySwap(returnPlayers, i + 1, partnerIndex);
            i += 2;
          }
        }
        if (returnPlayers.length === 0) continue;

        // Remove the selected players from their groups
        returnPlayers.forEach((player) => {
          groupedPlayers[playerGroups[player]].splice(
            groupedPlayers[playerGroups[player]].indexOf(player),
            1,
          );
        });

        return returnPlayers;
      }

      return [];
    };
    /**
     * Push players back to available groups
     */
    const pushPlayers = (players: string[]) => {
      players.forEach((player) => {
        groupedPlayers[playerGroups[player]].push(player);
      });
    };

    if (this.playerCount === 3) {
      debug("fill for 3");
      const firstThree = this.playerIds.filter(isPresent) as string[];
      // Check if all existing players in this game have the same group
      const gamePlayerGroups = firstThree.map((id) => playerGroups[id as string]);
      const allSameGroup = gamePlayerGroups.every((group) => group === gamePlayerGroups[0]);

      if (allSameGroup) {
        // Find a final player in groupPlayers with that group. We do not do
        // exclusion here because that must have already been manually
        // overridden
        const group = gamePlayerGroups[0];
        const players = popNPlayers(1, { group });
        if (players.length === 1) {
          debug("found for same group");
          return this.addPlayers(players);
        }
      }

      // Find the group with only one player in the game
      const singlePlayerGroup = gamePlayerGroups.find(
        (group) => gamePlayerGroups.filter((g) => g === group).length === 1,
      );

      if (singlePlayerGroup) {
        // Find a player in groupedPlayers with the same group as singlePlayerGroup
        const players = popNPlayers(1, {
          group: singlePlayerGroup,
          canPlay: firstThree,
        });
        if (players.length === 1) {
          debug("found for single player group");
          return this.addPlayers(players);
        }
      }

      // Add any player to the group
      const players = popNPlayers(1, { canPlay: firstThree });
      if (players.length === 1) {
        debug("found random");
        return this.addPlayers(players);
      }
    }
    if (this.playerCount === 1) {
      debug("fill for 1");
      // In this branch, the game already has 1 player assigned
      const firstPlayer = this.activePlayerIds();
      // Find another player with the same group as the existing player
      const existingPlayerGroup = playerGroups[firstPlayer[0]];
      let players = popNPlayers(1, { group: existingPlayerGroup });
      if (players.length === 1) {
        // Now delegate to fill(2)
        this.addPlayers(players);
        this.fill(groupedPlayers, playerGroups, groupExclusions, playerPairs);
        if (this.valid()) return;
        this.removePlayers(players);
        pushPlayers(players);
      }

      // We couldn't find another player with the same group as the existing player
      const otherPlayer = popNPlayers(1, { canPlay: firstPlayer });
      if (otherPlayer.length === 1) {
        // Now delegate to fill(2)
        this.addPlayers(otherPlayer);
        this.fill(groupedPlayers, playerGroups, groupExclusions, playerPairs);
        if (this.valid()) return;
        this.removePlayers(otherPlayer);
        pushPlayers(otherPlayer);
      }
    }
    if (this.playerCount === 2) {
      debug("fill for 2");
      const firstTwo = this.activePlayerIds();

      if (playerGroups[firstTwo[0]] === playerGroups[firstTwo[1]]) {
        const secondTwo = popNPlayers(2, { canPlay: firstTwo });
        if (secondTwo.length === 2) {
          debug("found same group balanced");
          return this.addPlayers(secondTwo);
        } else {
          // Couldn't find another 2 that could play so push back
          debug("failed to find same group balanced");
          pushPlayers(secondTwo);
        }
      } else {
        const secondTwo = [
          ...popNPlayers(1, {
            group: playerGroups[firstTwo[0]],
          }),
          ...popNPlayers(1, {
            group: playerGroups[firstTwo[1]],
          }),
        ];

        if (secondTwo.length === 2) {
          debug("found different group balanced");
          return this.addPlayers(secondTwo);
        } else {
          // Couldn't find another 2 that could play so push back
          debug("failed to find different group balanced");
          pushPlayers(secondTwo);
        }
      }

      // Try grabbing from one group / pairs
      {
        const players = popNPlayers(2, { canPlay: firstTwo });
        if (players.length === 2) {
          debug("found same group");
          return this.addPlayers(players);
        } else {
          debug("failed to find same group");
          pushPlayers(players);
        }
      }

      // Assign the next available players from any groups
      {
        const players = [...popNPlayers(1, { canPlay: firstTwo }), ...popNPlayers(1, { canPlay: firstTwo })];

        if (players.length === 2) {
          debug("found random");
          this.addPlayers(players);
          debug(this.playerIds);
          return;
        } else {
          debug("failed to find random");
          pushPlayers(players);
        }
      }
    }
    if (this.playerCount === 0) {
      debug("fill for 0");

      const firstTwo = popNPlayers(2);

      if (firstTwo.length === 2) {
        // Let fill(2) do the rest
        this.addPlayers(firstTwo);
        debug("fill for 0 found 2 players same group");

        this.fill(groupedPlayers, playerGroups, groupExclusions, playerPairs);
        // @ts-expect-error this is a getter but ts thinks it can't change from when we checked above?!
        if (this.playerCount === 2) {
          debug("failed to find balanced players");
          this.clear();
          pushPlayers(firstTwo);
        } else {
          return;
        }
      }

      // We couldn't find a good balance so lets do it randomly
      const players = popNPlayers(1);

      if (players.length === 1) {
        this.addPlayers(players);
        // Now let fill(1) do the rest
        this.fill(groupedPlayers, playerGroups, groupExclusions, playerPairs);

        // @ts-ignore
        if (this.playerCount === 1 || this.playerCount === 3) {
          // It didn't work, so remove the players and exit
          debug("failed to find random players");
          this.clear();
          pushPlayers(players);
        } else {
          debug("found random");
        }
      }
    }
  }

  override create() {
    if (isNil(this.game_set_id)) throw new ValidationError({ game_set_id: "Game set id is required" });
    return super.create();
  }

  isWinner(playerId: string): boolean {
    return this.winners.has(playerId) ?? false;
  }

  isLoser(playerId: string): boolean {
    return this.losers.has(playerId) ?? false;
  }

  markWon(playerId: string) {
    let { winners, losers } = this;
    const playerIds = this.activePlayerIds();
    if (!playerIds.includes(playerId)) throw new Error("Player is not in this game");

    if (this.winners.has(playerId)) return this.unmarkWon(playerId);
    if (playerIds.length % 2 === 0 && winners.size === playerIds.length / 2) return;

    winners.add(playerId);
    losers.delete(playerId);

    if (playerIds.length % 2 === 0 && winners.size === playerIds.length / 2) {
      losers = new Set(playerIds.filter((id) => !winners.has(id!)));
    }

    this.players = {
      ...this.players!,
      winners: Array.from(winners),
      losers: Array.from(losers),
    };
  }

  unmarkWon(playerId: string) {
    const { winners, losers } = this;
    if (!winners.has(playerId)) throw new Error("Player is not marked as won");

    winners.delete(playerId);
    losers.clear();

    this.players = {
      ...this.players!,
      winners: Array.from(winners),
      losers: Array.from(losers),
    };
  }

  save(): Promise<void> {
    debug("Saving game", this.index, this.id);
    return super.save();
  }

  markClean(): void {
    debug("Marking game clean", this.index, this.id);
    super.markClean();
  }
}

export default Game;
