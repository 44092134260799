export interface ComponentRoute {
  name: string;
  component: () => Promise<any>;
  requiresLogin?: boolean;
  stop?: boolean;
  child?: boolean;
}

type RouteParam<Path, NextPart> = Path extends `:${infer Param}`
  ? Record<Param, string> & NextPart
  : NextPart;

type RouteParams<Path> = Path extends `${infer Segment}/${infer Rest}`
  ? RouteParam<Segment, RouteParams<Rest>>
  : RouteParam<Path, {}>;

export const routes = {
  "/invite/accept/:inviteToken": {
    name: "acceptInvite",
    component: () => import("components/Pages/Invite/Accept"),
    stop: true,
  },
  "/create_account": {
    name: "createAccount",
    component: () => import("components/Pages/Account/CreateAccount"),
    requiresLogin: true,
    stop: true,
  },
  "/account/credits": {
    name: "accountCredits",
    component: () => import("components/Pages/Account/Tabs/Credits"),
    requiresLogin: true,
    stop: true,
  },
  "/account/archived_clubs": {
    name: "archivedClubs",
    component: () => import("components/modals/ArchivedClubs/ArchivedClubs"),
    requiresLogin: true,
  },
  "/account/clubs/new": {
    name: "newClub",
    component: () => import("components/modals/NewClub/NewClub"),
    requiresLogin: true,
  },
  "/account/invite": {
    name: "inviteAccountUser",
    component: () => import("components/modals/InviteAccountUser/InviteAccountUser"),
    requiresLogin: true,
  },
  "/account": {
    name: "account",
    component: () => import("components/Pages/Account/Account"),
    requiresLogin: true,
    stop: true,
  },
  "/profile": {
    name: "profile",
    component: () => import("components/Pages/Profile/Profile"),
    requiresLogin: true,
    stop: true,
  },
  "/clubs/:clubId/players/new": {
    name: "newPlayer",
    component: () => import("components/modals/ClubPlayers/NewPlayer"),
    requiresLogin: true,
  },
  "/clubs/:clubId/players/:id/edit": {
    name: "editPlayer",
    component: () => import("components/modals/ClubPlayers/EditPlayer"),
    requiresLogin: true,
  },
  "/clubs/:clubId/players": {
    name: "clubPlayers",
    component: () => import("components/Pages/Club/ClubPlayer/List"),
    requiresLogin: true,
    stop: true,
  },
  "/clubs/:clubId/groups": {
    name: "clubGroups",
    component: () => import("components/Pages/Club/Groups/List"),
    requiresLogin: true,
    stop: true,
  },
  "/clubs/:clubId/sessions/new": {
    name: "newClubSession",
    component: () => import("components/modals/Sessions/NewSession"),
    requiresLogin: true,
  },
  "/clubs/:clubId/events/new": {
    name: "newEvent",
    component: () => import("components/modals/Events/NewEvent"),
    requiresLogin: true,
  },
  "/clubs/:clubId/events/:eventId/edit": {
    name: "editEvent",
    component: () => import("components/modals/Events/EditEvent"),
    requiresLogin: true,
  },
  "/clubs/:clubId/events/:recurringSessionId/sessions/new": {
    name: "newEventSession",
    component: () => import("components/modals/Sessions/NewSession"),
    requiresLogin: true,
  },
  "/clubs/:clubId/events/:recurringSessionId/list": {
    name: "listSessions",
    component: () => import("components/modals/Sessions/ListSessions"),
    requiresLogin: true,
  },
  "/clubs/:clubId/nights/list": {
    name: "listClubNights",
    component: () => import("components/modals/Sessions/ListSessions"),
    requiresLogin: true,
  },
  "/clubs/:id/edit": {
    name: "editClub",
    component: () => import("components/modals/EditClub/EditClub"),
    requiresLogin: true,
  },
  "/clubs/:clubId": {
    name: "club",
    component: () => import("components/Pages/Club/Club"),
    requiresLogin: true,
    stop: true,
  },
  "/t/:token/stats": {
    name: "sessionStats",
    component: () => import("components/modals/Sessions/Stats"),
  },
  "/t/:token/log": {
    name: "sessionLog",
    component: () => import("components/modals/Sessions/IncidentLog"),
  },
  "/t/:token/setup": {
    name: "sessionSettings",
    component: () => import("components/modals/Sessions/Settings/Settings"),
  },
  "/t/:token/qr_scan": {
    name: "sessionScan",
    component: () => import("components/modals/QrScanner/QrScanner"),
  },
  "/t/:token/token_association/:token_notification_id": {
    name: "tokenAssociation",
    component: () => import("components/modals/TokenAssociation/TokenAssociation"),
  },
  "/t/:token/preview": {
    name: "sessionPreview",
    component: () => import("components/Pages/Night/PreviewMode"),
    child: true,
  },
  "/t/:token": {
    name: "session",
    component: () => import("components/Pages/Night/Night"),
    stop: true,
  },
  "/login": {
    name: "login",
    component: () => import("components/Pages/LogIn/LogIn"),
    stop: true,
  },
  "/forgot_password": {
    name: "forgotPassword",
    component: () => import("components/Pages/LogIn/ForgotPassword"),
    stop: true,
  },
  "/reset_password": {
    name: "resetPassword",
    component: () => import("components/Pages/LogIn/PasswordRecovery"),
    requiresLogin: true,
    stop: true,
  },
  "/signup": {
    name: "signup",
    component: () => import("components/modals/SignUp/SignUp"),
    stop: true,
  },
  "/create": {
    name: "freeSession",
    component: () => import("components/modals/CreateFreeNight/CreateFreeNight"),
    stop: true,
  },
  "/not_found": {
    name: "notFound",
    component: () => import("components/Pages/NotFound/NotFound"),
    stop: true,
  },
  "/": {
    name: "home",
    component: () => import("components/Pages/Home/Home"),
    stop: true,
  },
} as const;

type Routes = typeof routes;
type RouteNameToPath<Name extends string> = {
  [K in keyof Routes]: Routes[K]["name"] extends Name ? K : never;
}[keyof Routes];
type RoutesNames = (typeof routes)[keyof typeof routes]["name"];

type RouteHelpers = {
  [K in RoutesNames]: (params: RouteParams<RouteNameToPath<K>>) => string;
};

export const routeHelpers: RouteHelpers = Object.fromEntries(
  Object.entries(routes).map(([path, route]) => [
    route.name,
    (params: Record<string, string | number>) => {
      const pathWithParams = Object.entries(params || {}).reduce((path, [key, value]) => {
        return path.replace(`:${key}`, String(value).toString());
      }, path);

      return pathWithParams;
    },
  ]),
) as unknown as RouteHelpers;
