import { keys } from "@mixitone/util";
import { Field, InsertQuery, Query, QueryFilter, QueryFilterValue, UpdateQuery } from "..";
import { ReferenceDescriptor } from "../reference";
import { CountResponse, DataResponse, DatabaseAdapter } from "./DatabaseAdapter";
import { SupabaseClient } from "@supabase/supabase-js";

function applyFilter<B>(builder: B, filter: QueryFilter<any>) {
  return keys(filter).reduce((acc, k) => {
    const column = k;
    const value = filter[k] as QueryFilterValue<any>;
    if (!value) return acc;

    const fn = acc[value.op as unknown as keyof typeof acc] as (k: string, v: any) => any;
    if (!fn) {
      console.log("no fn for", value);
    }
    return fn.apply(acc, [column as string, value.value]);
  }, builder);
}

class SupabaseBuilder {
  constructor(
    private supabase: SupabaseClient<any>,
    private query: Query<any, any>,
  ) {}

  get builder() {
    return this.supabase.from(this.query.tableName);
  }

  get builderForCount() {
    return this.applyFilter(this.builder.select(this.selection, { count: "exact", head: true }));
  }

  get builderWithFilter() {
    let builder = this.applyFilter(this.builder.select(this.selection));
    if (this.query.options.limit) {
      builder = builder.limit(this.query.options.limit);
    }
    return builder;
  }

  get builderWithOrder() {
    return this.applyOrder(this.builderWithFilter);
  }

  get selection() {
    const selection = [...this.query.selection];

    if (this.query.options.preloadCounts) {
      for (const collection of this.query.options.preloadCounts) {
        selection.push(`${String(collection)}(count)`);
      }
    }

    if (this.query.preloadReferences) {
      for (const reference of this.query.preloadReferences) {
        const referenceConfig = this.query.modelClass.config.references[
          reference
        ] as ReferenceDescriptor<any>;
        selection.push(`${String(reference)}:${referenceConfig.key}(*)`);
      }
    }

    if (this.query.joinQueries) {
      for (const join of this.query.joinQueries) {
        selection.push(`${join.tableName}!inner(${join.selection})`);
      }
    }

    return selection.join(",");
  }

  private applyFilter(builder: ReturnType<ReturnType<(typeof this.supabase)["from"]>["select"]>) {
    const filter: QueryFilter<any> = {
      is_deleted: { op: "eq", value: false },
      ...this.query.filter,
      ...this.query.joinsFilter,
    };
    return applyFilter(builder, filter);
  }

  private applyOrder(builder: ReturnType<ReturnType<(typeof this.supabase)["from"]>["select"]>) {
    if (this.query.order) {
      return builder.order(this.query.order.column, {
        ascending: this.query.order.direction === "asc",
      });
    } else {
      return builder;
    }
  }
}

class SupabaseInsertBuilder {
  constructor(
    private supabase: SupabaseClient<any>,
    private query: InsertQuery<any, any>,
  ) {}

  get builder() {
    return this.supabase.from(this.query.tableName);
  }

  get selection() {
    return this.query.selection.join(",");
  }

  async insert(data: Record<string, any>) {
    return this.builder
      .insert(data)
      .select(this.selection)
      .single()
      .then((r) => r);
  }
}

class SupabaseUpdateBuilder {
  constructor(
    private supabase: SupabaseClient<any>,
    private query: UpdateQuery<any, any>,
  ) {}

  get builder() {
    return this.supabase.from(this.query.tableName);
  }

  get selection() {
    return this.query.selection.join(",");
  }

  async update(data: Record<string, any>) {
    return applyFilter(this.builder.update(data), this.query.filter)
      .select(this.selection)
      .single()
      .then((r) => r);
  }

  async delete() {
    if (Object.keys(this.query.filter).length === 0) {
      throw new Error("Supabase does not allow deleting all records");
    }
    if (!this.query.modelClass.hasField("is_deleted")) {
      throw new Error("Supabase does not allow deleting records without is_deleted field");
    }

    let updates: Record<string, any> = {
      is_deleted: true,
    };

    if (this.query.modelClass.hasField("updated_at")) {
      updates["updated_at"] = new Date().toISOString();
    }

    return applyFilter(this.builder.update(updates), this.query.filter).then((r) => r);
  }
}

export default class SupabaseDatabaseAdapter extends DatabaseAdapter {
  constructor(public supabase: SupabaseClient) {
    super();
  }

  convertType(_field: Field, value: any) {
    return value;
  }

  override async execute<Data>(query: Query<any, any>): Promise<DataResponse<Data>> {
    let builder = new SupabaseBuilder(this.supabase, query).builderWithOrder;
    const response = await builder.then((r) => r);
    return response as DataResponse<Data>;
  }

  override async count(query: Query<any, any>): Promise<CountResponse> {
    let builder = new SupabaseBuilder(this.supabase, query).builderForCount;
    const response = await builder.then((r) => r);
    return response;
  }

  override async insert<Data>(
    query: InsertQuery<any, any>,
    data: Record<string, any>,
  ): Promise<DataResponse<Data>> {
    let builder = new SupabaseInsertBuilder(this.supabase, query);
    const response = await builder.insert(data);
    return response as DataResponse<Data>;
  }

  override async update<Data>(
    query: UpdateQuery<any, any>,
    data: Record<string, any>,
  ): Promise<DataResponse<Data>> {
    let builder = new SupabaseUpdateBuilder(this.supabase, query);
    const response = await builder.update(data);
    return response as DataResponse<Data>;
  }

  override async delete(query: UpdateQuery<any, any>): Promise<CountResponse> {
    let builder = new SupabaseUpdateBuilder(this.supabase, query);
    const response = await builder.delete();
    return response;
  }
}
