import { Model, ModelAttributes, collection, model, modelConfig, reference } from "@mixitone/oom";
import AccountUser from "./AccountUser";
import ClubPlayer from "./ClubPlayer";
import Group, { defaultColors } from "./Group";
import { isNil } from "@mixitone/util";
import Color from "color";
import Night from "./Night";

export interface ClubPreferences {
  enableQrScanning?: boolean;
}

const config = modelConfig(
  {
    account_id: {
      type: "string",
    },
    address: {
      type: "string",
    },
    contact_id: {
      type: "string",
    },
    created_at: {
      type: "datetime",
    },
    is_deleted: {
      type: "boolean",
    },
    is_temporary: {
      type: "boolean",
    },
    name: {
      type: "string",
    },
    preferences: {
      type: "json",
      jsonType: {} as ClubPreferences,
    },
    updated_at: {
      type: "datetime",
    },
  },
  {
    club_players: collection<ClubPlayer>(() => ClubPlayer, "club_id"),
    groups: collection<Group>(() => Group, "club_id", { order: "index" }),
  },
  {
    contact: reference(() => AccountUser, "contact_id"),
  },
);

interface Club extends ModelAttributes<typeof config> {}

@model("clubs", config)
class Club extends Model<typeof config> {
  override canEdit() {
    return AccountUser.current?.role === "Admin" || AccountUser.current?.id === this.contact_id;
  }

  async ensureGroups() {
    await this.groups.load();

    if (this.groups.length === 0) {
      const bGroup = new Group({
        account_id: this.account_id,
        club_id: this.id,
        name: "Beginner",
        index: 0,
      });
      const iGroup = new Group({
        account_id: this.account_id,
        club_id: this.id,
        name: "Intermediate",
        index: 1,
      });
      const aGroup = new Group({
        account_id: this.account_id,
        club_id: this.id,
        name: "Advanced",
        index: 2,
      });

      try {
        await Promise.all([bGroup.save(), iGroup.save(), aGroup.save()]);
        this.groups.push(bGroup, iGroup, aGroup);
      } catch (e) {
        await this.groups.reload();
      }
    } else if (this.groups.some((g) => isNil(g.color))) {
      this.groups.forEach((g, i) => {
        g.color = Color(defaultColors[i % defaultColors.length]).rgbNumber();
      });
      await this.groups.save();
    }

    if (this.groups.length === 0) {
      throw new Error("Failed to create groups");
    }
  }

  async startSession() {
    const night = await Night.createAndSetup({
      account_id: this.account_id,
      club_id: this.id,
    });
    if (!night) throw new Error("Failed to create night");
    return night.token!;
  }

  override async create() {
    const result = await super.create();
    await this.ensureGroups();
    return result;
  }

  /**
   * Returns a map of group IDs to an array of group IDs that the group excludes
   */
  get groupExclusions() {
    const groupExclusions: Record<string, string[]> = {};

    for (const group of this.groups) {
      const { id, excludesGroups } = group;
      if (!id || !excludesGroups) continue;

      if (!groupExclusions[id]) groupExclusions[id] = [];
      for (const groupId of excludesGroups) {
        const groupIdString = String(groupId);
        groupExclusions[id].push(groupIdString);

        if (!groupExclusions[groupIdString]) groupExclusions[groupIdString] = [];
        groupExclusions[groupIdString].push(id);
      }
    }

    return groupExclusions;
  }
}
export default Club;
